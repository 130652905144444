<template>
  <div class="text-white">
    <div class="container my-5">
      <div class="row w-lg-50">
        <h1 class="mb-4">About Me</h1>
        <div class="col-lg-6 col-12 d-flex align-items-center">
          <div>
            <p class="mb-2">
              I am Martijn Koch and I am living in Nuenen next to Eindhoven in
              The Netherlands. Currently, I am employed at
              <a
                target="_blank"
                href="https://www.iodigital.com/"
                class="text-white fw-bold text-decoration-none"
                >iO Digital</a
              >!
            </p>
            <p>
              In my free time I like to play tennis, watch a movie with my
              lovely girlfriend or spending some time unplanned playing video
              games. Besides that, I also enjoy designing and improving my web
              development skills. In the future, I hope to own a vineyard!
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <img src="@/assets/images/chrome_ENWzWpoQDA.png" />
        </div>
      </div>
    </div>

    <div class="container left">
      <div class="row text-left about-text">
        <div class="col-12">
          <h1>Work expercience</h1>
        </div>
        <div class="row m-0 p-0">
          <div v-for="job in jobs" class="col-lg-4 col-12">
            <h2 class="pt-3 pb-3">{{ job.Company }}</h2>
            <p>{{ job.JobTitle }}</p>
            <p>
              {{ job.Text }}
            </p>
          </div>
        </div>
      </div>
      <div class="row text-left about-text">
        <div class="col-12">
          <h1>Education</h1>
        </div>
        <div class="col-lg-4">
          <h2 class="pt-3 pb-3">Fontys, Tilburg 2015-2020</h2>
          <p>IT and Media Design</p>
        </div>
        <div class="col-lg-4">
          <h2 class="pt-3 pb-3">Sint Lucas, Eindhoven 2010-2014</h2>
          <p>Audiovisual design and animation</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jobs from "../data/workExperience.json";
export default {
  data() {
    return {
      jobs: jobs.data,
    };
  },
  metaInfo() {
    return {
      title: "Martijn Koch - About",
      meta: [
        {
          vmid: "Martijn Koch - About",
          name: "Martijn Koch - About",
          content: "Martijn Koch - About",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.about-text {
  margin-bottom: 4rem;
  h2 {
    font-size: 16pt !important;
    padding-bottom: 0 !important;
    font-weight: 700;
    text-decoration: underline;
  }
  .col-lg-4 {
    p {
      &:first-of-type {
        font-weight: 500;
      }
      color: #dfe4ea;
      font-weight: 300;
      font-size: 12pt;
    }
  }
}
img {
  border-radius: 8px;
  height: 250px;
  width: 100%;
  object-fit: contain;
}
</style>
