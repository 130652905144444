<template>
  <div>
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-md-5 col-sm-6 pl-0">
              <div class="logocontainer">
                <div class="flex-shrink-0 flex items-center">
                  <a href="/">
                    <lottie
                      :options="defaultOptions"
                      :height="55"
                      :width="55"
                      v-on:animCreated="handleAnimation"
                    />
                  </a>
                </div>
              </div>
          </div>
          <section class="widget widget_text">
              <div class="textwidget row">
                <p class="text-white left mt-4 my-auto">
                  ©{{ getCurrentYear }} Martijn Koch All Rights
                  Reserved
                </p>
              </div>
            </section>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import animationData from "@/assets/logo.json";

export default {
  name: "Footer",
  data() {
    return {
      defaultOptions: { animationData: animationData },
      animationSpeed: 1,
    };
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    },

    stop: function () {
      this.anim.stop();
    },

    play: function () {
      this.anim.play();
    },

    pause: function () {
      this.anim.pause();
    },

    onSpeedChange: function () {
      this.anim.setSpeed(this.animationSpeed);
    },
  },
  computed: {
    getCurrentYear() {
      return new Date().getFullYear();
    }
  }
};
</script>
<style lang="scss" scoped>
footer {
  padding-top: 50px;
  padding-bottom: 50px;
}
.logocontainer {
  float: left;
}
</style>
