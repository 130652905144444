<template>
  <div class="container">
    <div class="row text-white title">
      <h1 class="line1">I'm an Eindhoven based creative developer</h1>
      <h2 class="line2">Martijn is currently emoloyed at Happy Horizon</h2>
    </div>

    <section class="container mx-auto text-white mt-12 info">
      <div class="row">
        <div class="col-md-6 col-sm-6">
          <div class="widget widget_nav_menu">
            <h3 class="widget-title">info</h3>
            <ul>
              <li>
                <a href="#">Home</a>
              </li>
              <li>
                <router-link :to="{ name: 'About' }">About</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 offset-md-3">
          <div class="widget widget_nav_menu">
            <h3 class="widget-title">social</h3>
            <ul>
              <li
                id="menu-item-85"
                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-85"
              >
                <a href="https://github.com/martijnkoch" target="_blank"
                  >Github</a
                >
              </li>
              <li
                id="menu-item-84"
                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-84"
              >
                <a
                  href="https://www.linkedin.com/in/martijn-koch-a1728a62/"
                  target="_blank"
                  >Linkedin</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import anime from "animejs";

export default {
  name: "HomeHeading",
  mounted() {
    const line1 = document.querySelector(".line1");
    const line2 = document.querySelector(".line2");

    line1.innerHTML = line1.textContent.replace(
      /\S/g,
      "<span class='letters-1'>$&</span>"
    );

    line2.innerHTML = line2.textContent.replace(
      /\S/g,
      "<span class='letters-2'>$&</span>"
    );

    anime.timeline({ loop: false }).add({
      targets: ".letters-1",
      opacity: [0, 1],
      easing: "linear",
      duration: 1000,
      delay: function (el, i) {
        return 100 * (i + 1);
      },
    });

    anime.timeline({ loop: false }).add({
      targets: ".letters-2",
      opacity: [0, 1],
      easing: "linear",
      duration: 1000,
      delay: function (el, i) {
        return 60 * (i + 1);
      },
    });
  },
};
</script>

<style lang="scss" scoped>
.line2 {
  font-weight: 300;
}
.title {
  text-align: left;
}
.info {
  margin-top: 80px;
  margin-bottom: 40px;
  img {
    margin-bottom: 20px;
  }
  .widget {
    margin: 0 0 2em;
    margin-bottom: 40px;
    padding: 0;
    text-align: left;
    ul li a,
    p {
      color: #dfe4ea;
      font-weight: 300;
    }
    ul {
      padding: 0;
      list-style-type: none;
    }
    a {
      text-decoration: none;
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: -4px;
        left: 0;
        background-color: #dfe4ea;
        visibility: hidden;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transition: all 0.25s linear;
        transition: all 0.25s linear;
        -ms-transform-origin: 0% 0%;
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
      }
      position: relative;
      &:hover {
        text-decoration: none;
        &:before {
          visibility: visible;
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
          transition: all 0.25s linear;
        }
      }
    }
  }
}

.widget-title {
  letter-spacing: 3px;
  font-size: 12px;
  color: white;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.textwidget {
  font-size: 14px;
  line-height: 1.1em;
  p {
    font-size: 12px;
  }
}
</style>
