<template>
  <div>
    <HomeHeading />
    <Projects />
  </div>
</template>

<script>
import HomeHeading from "@/components/HomeHeading.vue";
import Projects from "@/components/Projects";

export default {
  name: "Home",
  components: {
    HomeHeading,
    Projects,
  },
  metaInfo() {
    return {
      title: "Martijn Koch - Portfolio",
      meta: [
        {
          vmid: "Martijn Koch - Portfolio",
          name: "Martijn Koch - Portfolio",
          content: "Martijn Koch - Portfolio",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
