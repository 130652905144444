<template>
  <div id="app">
    <Logo />
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import WOW from "wowjs";
import Logo from "./components/Logo.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  metaInfo: {
    title: "Martijn Koch - Portfolio",
  },
  components: {
    Logo,
    Footer,
  },
  mounted() {
    const wow = new WOW.WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: true,
    });
    wow.init();
  },
};
</script>

<style lang="scss">
//Google Font
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed");
$primary-font: "Roboto Condensed", sans-serif;

html {
  font-family: $primary-font !important;
}

body {
  background-image: linear-gradient(90deg, #2f3542, #3a4e61);
}

.left {
  text-align: left;
}
</style>
