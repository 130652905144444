import Vue from "vue";
import App from "./App.vue";
import Lottie from "vue-lottie";
import router from "./router";
import VueLazyLoadVideo from "vue-lazyload-video";
import VueAnime from "vue-animejs";
import Meta from "vue-meta";
import BootstrapVue from "bootstrap-vue";

//Css files
import "bootstrap/dist/css/bootstrap.css";
//import "bootstrap-vue/dist/bootstrap-vue.css";
import "animate.css/animate.css";

//Plugins
const plugins = [VueAnime, Meta, BootstrapVue, VueLazyLoadVideo];
plugins.forEach((plugin) => Vue.use(plugin));

Vue.component("lottie", Lottie);
Vue.config.productionTip = false;

new Vue({
  el: "#app",
  router,
  render: (h) => h(App)
}).$mount("#app");
