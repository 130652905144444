import Vue from "vue";
import Router from "vue-router";

import Home from "@/views/Home";
import About from "@/views/About";

import Zuiver from "@/views/projects/Zuiver";
import Maerschalk from "@/views/projects/Maerschalk";
import DataMiljonair from "@/views/projects/DataMiljonair";

import PageNotFound from "@/views/PageNotFound";

Vue.use(Router);

export default new Router({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    {
      path: "/about",
      name: "About",
      component: About,
    },
    {
      path: "/zuiver",
      name: "Zuiver",
      component: Zuiver,
    },
    {
      path: "/maerschalk",
      name: "Maerschalk",
      component: Maerschalk,
    },
    {
      path: "/datamiljonair",
      name: "DataMiljonair",
      component: DataMiljonair,
    },
    {
      path: "/:catchAll(.*)*",
      name: "PageNotFound",
      component: PageNotFound,
    },
  ],
  mode: "history",
});
