<template>
  <div class="container text-white">
    <div class="row">
      <h1 class="text-left text-4xl mb-5 mt-5">Data Miljonair</h1>
      <img src="@/assets/images/data-miljonair.jpg" class="fluid" />
      <div class="row project-content">
        <div class="col-12">
          <p class="pt-5 pb-5">
            During the minor Future Media, we had a collaboration with VPRO
            Medialab. The assignment VPRO gave us was to make visitors at the
            Dutch Design week more conscious about the value of online data. My
            teammates and I decided to create an aracde machine where the
            visitors could play a video game to become more aware of privacy. In
            general, people do know that data is worth money to companies. But
            how far are people willing to go if they are in the heat of a game?
            And if there is a fun prize at the end?
          </p>
        </div>
        <div class="process col-12">
          <b-img
            src="@/assets/images/case-building.jpg"
            fluid
            alt="Maerschalk 3d box"
            class="col-lg-6 col-md-12 col-sm-12 p-2 wow animate__animated animate__fadeInLeft"
            data-wow-duration="2.5s"
          ></b-img>
          <b-img
            src="@/assets/images/codeboys.jpg"
            fluid
            alt="Nodemcu"
            class="col-lg-6 col-md-12 col-sm-12 p-2 wow animate__animated animate__fadeInLeft"
            data-wow-duration="2.5s"
          ></b-img>

          <p class="pt-5 pb-4">
            During the process of making the arcade machine, my tasks were
            mainly to create the front-end for the interface and building the
            physical machine.
          </p>

          <b-img
            src="@/assets/images/interface-1.jpg"
            fluid
            alt="Maerschalk 3d box"
            class="col-lg-6 col-md-12 col-sm-12 p-2 wow animate__animated animate__fadeInLeft"
            data-wow-duration="2.5s"
          ></b-img>
          <b-img
            src="@/assets/images/interface-2.jpg"
            fluid
            alt="Nodemcu"
            class="col-lg-6 col-md-12 col-sm-12 p-2 wow animate__animated animate__fadeInLeft"
            data-wow-duration="2.5s"
          ></b-img>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "Martijn Koch - Data Miljonair",
      meta: [
        {
          vmid: "Martijn Koch - Data Miljonair",
          name: "Martijn Koch - Data Miljonair",
          content: "Martijn Koch - Data Miljonair",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
