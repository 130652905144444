<template>
  <div class="text-white">
    <h1>404 - Page Not Found</h1>
    <p>This page no longer exists or was moved to another location.</p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
