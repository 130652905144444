<template>
  <div class="container mx-auto text-white">
    <h1 class="left mb-5 mt-5">Maerschalk Batsignal</h1>
    <div class="row project-content">
      <div class="col-12">
        <p class="pt-5 left">
          During semester 6 one of our clients was Maerschalk. Maerschalk is an
          online media agency and they were looking for a creative way to
          improve their b2b customer relationships. When a client of Maerschalk
          would have a problem or question they would just call the person they
          had interaction with before. This resulted in a lot of unnecessary
          bothers during work hours.
        </p>
        <p class="pb-5 left">
          Our idea was to create a Maerschalk batsignal. The customers of
          Maerschalk see them as superheroes. Whenever there is a problem
          Maerschalk comes and fixes it. We 3d printed a box with NodeMCU inside
          of it. When the button was pressed on a POST request would be sent to
          the API and the batsignal would go off. My tasks were to create the
          API, design the web dashboard. And I also helped on the front-end. For
          the API I chose PHP on the back-end with SlimPHP as a framework. On
          the front-end, we chose Vuejs with Vue bootstrap. The code can be
          found at
          <a href="https://github.com/martijnkoch/Maerschalk-">Github</a>.
        </p>
      </div>
      <div
        class="process col-12 wow animate__animated animate__fadeInLeft"
        data-wow-duration="2.5s"
      >
        <b-img-lazy
          src="@/assets/images/3d-box.jpg"
          fluid
          alt="Maerschalk 3d box"
          class="col-lg-6 col-md-12 col-sm-12 p-2"
        ></b-img-lazy>
        <b-img-lazy
          src="@/assets/images/nodemcu.jpg"
          fluid
          alt="Nodemcu"
          class="col-lg-6 col-md-12 col-sm-12 p-2"
        ></b-img-lazy>
      </div>

      <div
        class="process col-12 wow animate__animated animate__fadeInLeft"
        data-wow-duration="2.5s"
      >
        <b-img-lazy
          src="@/assets/images/dashboard-1.jpg"
          fluid
          alt="Maerschalk dashboard"
          class="mx-auto d-block mt-5 mb-5"
        ></b-img-lazy>

        <b-img-lazy
          src="@/assets/images/dashboard-3.jpg"
          fluid
          alt="Maerschalk dashboard"
          class="mx-auto d-block"
        ></b-img-lazy>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "Martijn Koch - Maerschalk",
      meta: [
        {
          vmid: "Martijn Koch - Maerschalk",
          name: "Martijn Koch - Maerschalk",
          content: "Martijn Koch - Maerschalk",
        },
      ],
    };
  },
};
</script>

<style scoped>
a {
  color: rgb(6 182 212) !important;
}
</style>
