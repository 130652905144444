<template>
  <div class="container text-white">
    <div class="row">
      <h1 class="text-left text-4xl mb-5 mt-5">Zuiver.one</h1>
      <div class="row project-content">
        <div class="col-12">
          <p class="pt-5">
            During the pandemic I went looking for a new desk mat at my home
            office. Noticeable was the lack of EU vendors for uniquely designed
            deskmats. After market research we concluded that there was a lack
            of decent EU competitors. Thus we decided to design our own deskmats
            and find a fitting manufacturer.
          </p>
          <p class="pb-5 left">
            In early 2024, we successfully launched a revamped iteration of the
            <a href="https://zuiver.one" target="_blank" class="text-white"
              >zuiver.one</a
            >
            website. The preceding version, constructed with Woocommerce,
            employed Bootstrap, jQuery, and PHP predominantly in the front-end
            development. Following a two-year period, we recognized the
            necessity for a comprehensive overhaul to enhance various aspects of
            the website. For the redevelopment, we opted for Nuxt 3 as a
            well-suited front-end framework. While the back-end continues to
            leverage Woocommerce, we have transitioned to utilizing API calls
            exclusively. Instead of Bootstrap, we have adopted Tailwind CSS for
            a more streamlined and sophisticated design. To optimize image
            compression on the front-end, we have integrated Cloudinary into our
            workflow. Additionally, the website is now hosted on AWS Amplify,
            facilitated by a robust CI/CD pipeline for seamless deployment and
            maintenance.
          </p>
        </div>
        <img
          src="@/assets/images/chrome_69NrIrZGcy.png"
          alt="zuiver website"
          class="fluid mb-5"
        />
        <img
          src="@/assets/images/chrome_8wok4nIqe8.png"
          alt="zuiver website"
          class="fluid mb-5"
        />
        <div
          class="process col-12 mb-5 wow animate__animated animate__fadeInLeft"
          data-wow-duration="2.5s"
        >
          <b-img-lazy
            src="@/assets/images/bunduru.png"
            fluid
            alt="Zuiver tripple box"
            class="col-lg-6 col-md-12 col-sm-12 p-2"
          ></b-img-lazy>
          <b-img-lazy
            src="@/assets/images/zuiver-dozen.jpg"
            fluid
            alt="Zuiver dozen"
            class="col-lg-6 col-md-12 col-sm-12 p-2"
          ></b-img-lazy>
        </div>
        <div
          class="process col-12 mb-5 wow animate__animated animate__fadeInLeft"
          data-wow-duration="2.5s"
        >
          <b-carousel
            id="carousel-no-animation"
            style="text-shadow: 0px 0px 2px #000"
            no-animation
            controls
            indicators
            img-width="1024"
            img-height="480"
          >
          <b-carousel-slide
              img-src="https://api.zuiver.one/wp-content/uploads/2024/01/monochrome-waves-top-down-keeb.webp"
            ></b-carousel-slide>
            <b-carousel-slide
              img-src="https://api.zuiver.one/wp-content/uploads/2024/01/handyman-white-top-view-keeb.webp"
            ></b-carousel-slide>
            <b-carousel-slide
              img-src="https://api.zuiver.one/wp-content/uploads/2024/01/hobbyman-black-top-down-keeb.webp"
            >
            </b-carousel-slide>
            <b-carousel-slide
              img-src="https://api.zuiver.one/wp-content/uploads/2024/01/coral-black-top-down-keeb.webp"
            ></b-carousel-slide>
            <b-carousel-slide
              img-src="https://api.zuiver.one/wp-content/uploads/2024/01/coral-white-top-down.webp"
            ></b-carousel-slide>
          </b-carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "Martijn Koch - Zuiver.one",
      meta: [
        {
          vmid: "Martijn Koch - Zuiver.one",
          name: "Martijn Koch - Zuiver.one",
          content: "Martijn Koch - Zuiver.one",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scroped>
.carousel-control-prev,
.carousel-control-next {
  span {
    &:nth-child(2) {
      display: none;
    }
  }
}
</style>
